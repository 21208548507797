.profile-myplans{
    display: flex;
    flex-direction: column;
    position: relative;
}

.profile-myplans-section-view{
    width: 60vw;
    /* height: 150vh;
    position: absolute; */
    background-color: #fff;
}
.profile-myplans-section-mobile-view{
    display: none;
}
.profile-myplans-container{
    width: 96%;
    background-color: #fff;
    height: auto;
    margin:1% 2%;
    border-radius: 9px;
    box-shadow: 2px 2px 20px 1px #e5edf5;
}

.profile-myplans-section{
    /* margin: 2%; */
    display: flex;
    flex-direction: column;
}
.profile-myplans-section-head{
    margin: 3% 0% 0% 3%;
}
.profile-myplans-section-head h1{
  word-spacing: 4px;
  margin: 2% 0;
  font-size: 2rem;

}
.profile-myplans-section-body{
    width: 100%;
    border-radius: 3px;
    box-shadow: 1px 1px 3px 1px #e5edf5;
    height: auto;
    position: relative;

}
.global-table-style-container h2{
    margin: 2% 0;
}
.table-td-inactive{
    background-color: rgb(255, 0, 0);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
}
.table-td-inactive:hover{
    background-color: rgb(253, 66, 66);
}
.table-td-active{
    background-color: rgb(0, 212, 53);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
}
.table-td-expired{
    background-color:#0008FF;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
}
.table-td-expired:hover{
    background-color:#3f45ec;
}
.profile-myplans-section-view-ctn{
    display: flex;
    flex-direction: column;
    padding: 2% 4%;
    /* width: 70%; */
   
}
.profile-myplans-section-view-head h1{
    word-spacing: 4px;
    margin: 2% 0;
    font-size: 2rem;
}
.profile-myplans-section-view-body{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2% 0
}
.profile-single-input{
    width: 48% !important;

}
.profile-single-input input{
   background-color: #ebf1ff !important;

} 
.profile-myplans-section-view-body-section{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.profile-myplans-section-view-footer{
    display: flex;
    justify-content: center;
    margin: 5% 0%;
}
.profile-myplans-section-view-cancel-btn{
    background-color: rgb(255, 0, 0);
    color: #fff;
    border: none;
    /* border-radius: 2px; */
    width: 20%;
    border-radius: 4px;
    height: 40px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    margin: auto 8px;
}
.profile-myplans-section-view-cancel-btn:hover{
    background-color: rgb(149, 22, 22);
}
.profile-myplans-section-view-renew-btn:hover{
    border: 2px solid rgb(3, 198, 0);
    background-color: #fefefe;
    color: rgb(0, 182, 0);
}
.profile-myplans-section-view-renew-btn{
    background-color: #fff;
    color: rgb(0 38 242);
    border: 2px solid rgb(0 38 242);
    width: 20%;
    border-radius: 4px;
    height: 40px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    margin: auto 8px;
}

.custom-login-date{
    width: 70%;
}
.custom-login-date p{
    font-size: 1rem;
    font-weight: 600;
    margin: 8% 0 2% 0;
}
.custom-login-date-ctn {
    width: 96%;
    background-color: #ebf1ff;
    height: 57px;
    border-radius: 4px;
    border: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
/* .custom-login-date-ctn input{
    border: none;
    font-size: 1rem;
    width: 75%;
    background-color: #ebf1ff;
    height: 48px;
    padding: 0% 0% 0% 10%;

}  

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    text-align: center;
}

.custom-login-date-input{
    position: relative;
} */

.global-table-style-container{
    /* padding: 1%; */
    margin: 2% 0;
    /* background-color: #fff; */
    max-height: 75vh;
    overflow-y: auto;
  }
  .global-table-style {
    width: 100%;
    /* background-color: #fff; */
    border-collapse: collapse;
  }
  .global-table-style thead {
    position: sticky;
    top: -6px;
   
    height: 42px;
    z-index: 2;
  }
  .global-table-style thead tr th {
  
    height: 100%;
    text-align: center;
    font-size: 0.75rem;
  }
  .global-table-style tbody {
    min-height: 36px;
  }
  .global-table-style tbody tr td {
    background-color: #fff;
    border-bottom: 1px solid #c0bebe;
    height: 100%;
    text-align: center;
    min-height: 50px;
    height: 40px;
    font-size: 1rem;
  }
  .global-table-style-container::-webkit-scrollbar {
    width: 4px;
    background-color: #f1f1f1;
  }
  .global-table-style-container::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
  .go-to-website{
    background: #e51448;
    color: #fff;
    width: 40px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .go-to-website p{
    font-size: 0.55rem;
    font-weight: 600;
  }
 
  .visibility-icon {
    color: #5ba329;
    font-size: 1.3rem;
  }
  .table-mobile{
    display: none;
  }
  .refund-confirm-dialog-container{
    width: 45vw;
    min-width: 40vw;
    background-color: #fff;
   
  }
  .refund-confirm-dialog-sub-container{
    display: flex;
    flex-direction: column;
    padding:4%;
    align-items: center;
  }
.refund-check{
    color: #5ba329;
    font-size: 5rem;
}
.refund-confirm-dialog-first-p{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 2% 0;
}
.refund-confirm-dialog-second-p{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 2% 0 8% 0;
}

/* my plan top container */
.profile-myplans-top-container{
    display: flex;
    padding: 2%;
}
.profile-myplans-top-left-container{
    width: 55%;
    background-color: #f6f5f8;
    border-radius: 8px;
    margin: 2% 1%;
    /* position: relative; */
    display: flex;
    justify-content: space-between;
    /* height: 150px; */
}
.my-plans-image{
    width:29%;
    height: auto;
}
.profile-myplans-top-left-container h1{
    font-size: 1.5rem;
   
}
.profile-myplans-top-left-container p{
    font-size: 1rem;
    color: #949396;
    margin: 4% 0;

  
}
.profile-myplans-top-right-container{
    width: 40%;
    margin: 2% 1%;
}
.profile-myplans-top-right-first-container{
    background-color: #e50000;
    margin: 0 2%;
    border-radius: 8px;
    display: flex;
    padding: 3%;
    align-items: center;
    justify-content: space-around;
    
}
.profile-myplans-top-right-first-container p{
   color: #fff;
   font-size: 1rem;
   margin: 0 6% 0 0;
}

.my-plans-all-package{
    
}
.profile-myplans-top-right-second-container{
    display: flex;
}
.profile-myplans-top-right-second-single-container{
    width: 50%;
    background-color: #e500c6;
    margin: 2%;
    border-radius: 8px;
    padding: 6% 2%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.profile-myplans-top-right-second-single-container p{
    color: #fff;
    font-size: 1rem;
}
.profile-myplans-second-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
   
    position: relative;
}
.profile-myplans-second-single-container{
    width: 30%;
    border-radius: 8px;
    display: flex;
}
.search-container-myplan{
    justify-content: space-between;
    background-color:#fff;
    height: 45px;
    align-items: center;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
    
}

.search-container-myplan input{
    background-color: #fff;
    border: none;
    width: 84%;
    height: 45px;
    padding: 0 0 0 2%;
    font-size: 1rem;
   
}
.search-container-myplan input:focus{
    outline: none;
}
.search-icon-my-plan{
    color: #fff;
    background-color: #000;
    height: 40px;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2% 0 0;
    border-radius: 4px;
}


.package-expiry{
    background-color: #fff;
    height: 45px;
    padding:0 2%;
    align-items: center;
    justify-content: space-between;
    color: blue;
    cursor: pointer;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
}
.package-expiry p{
margin: 0;
}
.package-expiry-drop-down{
    background-color: #fff;
    padding: 2% 1%;
    position: absolute;
    box-shadow: 0 0 8px 3px #eee;
    width: 29.6%;
    z-index: 3;
    top: 103%;
    left: 32.3%;
    border-radius: 4px;
}

.package-expiry-drop-down p{
    background-color: #f8f4f4;
    margin: 2% 0;
    padding: 2% 2% 2% 12%;
    font-size: 0.8rem;
    color: #3400e5;
    font-weight: 600;
}
.outlet-expiry-drop-down{
    background-color: #fff;
    padding: 2% 1%;
    position: absolute;
    box-shadow: 0 0 8px 3px #eee;
    z-index: 3;
    border-radius: 4px;
    width: 29.6%;
    top: 103%;
    left: 65.3%;
}
.outlet-expiry-drop-down p{
    background-color: #f8f4f4;
    margin: 2% 0;
    padding: 2% 2% 2% 12%;
    font-size: 0.8rem;
    color: #3400e5;
    font-weight: 600;
}

.renew-table-cell{
    background-color: #0a62bb !important;
    color: #fff;
    cursor: pointer;
}
.renew-table-cell p{
    font-size: 0.6rem;
}

  @media (max-width:720px){
    .table-mobile{
        display: table;
        margin: 5% 0;
    }
    .table-desktop{
        display: none;
    }
    .profile-myplans-section{
        padding: 5% 0;
    }
    .global-table-style-container h2{
        margin: 5% 0;
        margin-bottom: 10%;
    }

    .profile-myplans-section-view{
        display: none;
    }
    .profile-myplans-section-mobile-view{
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 4%;
    }
    .css-hz1bth-MuiDialog-container{
        align-items: start !important;
        padding-top: 7vh!important;
    }
    .css-1fu2e3p-MuiPaper-root-MuiDialog-paper{
        margin: 0!important;
        max-width: initial !important;
        width:96% !important;
    }
    .mobile-view-myplans-details-ctn{
        margin: 5% 0;
    }
    .mobile-view-myplans-details-section{
        display: flex;
        justify-content: space-between;
        padding: 5% 2%;
        background-color: #f5f5f5;
        font-weight: 600;
        border-radius: 4px;
        border-bottom: 0.5px solid #c3c3c3;
    }
    .mobile-view-plan-text{
        font-size: 0.9rem;
        font-weight: 500;
    }
    .profile-myplans-section-view-footer{
        width: 100%;
    }
    .profile-myplans-section-view-footer button{
        width: auto !important;
    }
    .profile-myplans-top-container{
        flex-direction: column;
    }
    .profile-myplans-top-left-container{
        width: 98%;
    }
    .profile-myplans-top-right-container{
        width: 98%;
    }
    .profile-myplans-second-container{
        flex-direction: column;
    } 
    .profile-myplans-second-single-container{
        width: 98%;
        margin: 1% 0;
    }
    .package-expiry-drop-down{
        width: 94%;
        left: 2%;
        top: 64%;
    }
    .outlet-expiry-drop-down{
        width: 94%;
        left: 2%;
        top: 100%;
    }
    .single-user-top-single-card{
        width: 98%;
        margin: 1% 0;
    }
  }


  /********* my plans new design  *********/
  .my-plans-main-container{
    width:100%;
    /* background-image: linear-gradient(to bottom, #ffffff 0, #ffffff 10vh, #d2d2f7 10vh, #a3a3f7 100%); */
     /* background-image: url('../../Assets/Images/New/v904-nunny-002-f.png'); */
     background-size: cover;
     background-image:linear-gradient(to bottom,#ffffff,#ddddf8);
  }
  .my-plans-container{
    width:90%;
    margin: 0 auto;
    min-height: 85vh;
    
  }
  .my-plans-container-one{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
    flex-wrap: wrap;
  }
  .my-plans-container-one-single-card{
    background-color: #fff;
    margin: 1% 0;
    height: 150px;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
  }
  .my-plans-container-one-single-card img{
    width: 45px;
  }
  .my-plans-number-label{
    font-size: 1.7rem;
    font-weight: 700;
    margin: 8px 
  }
  .my-plans-text-label{
    font-size: 0.9rem;
  }
  .renew-button-container{
    display: flex;
    justify-content: space-between;
    padding: 0 15%;
    margin: 2% 0;
  }
  .register-customer-container-second-radio{
    background-color: #eee;
    width: 200px;
    height: 30px;
    display: flex;
    padding: 1%;
    border-radius: 4px;
}
.register-customer-container-second-radio-single{
   width:50%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #eee;
   color: #000;
   font-size: 0.8rem;
   font-weight: 600;
   cursor: pointer;
   border-radius: 4px;
}
.selected{
    background-color: #2626f5;
    color: #fff;
}
.renew-erp-type{
    border: 1px dashed red;
    padding: 1% 4%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.renew-erp-type p{
    color: red;
    font-size: 0.8rem;
    font-weight: 600;
}
.register-customer-container-third{
    display: flex;
    justify-content: center;
}
.register-customer-container-third-single{
    width: 280px;
    height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 12px; 
    margin: 4%;
}
.register-customer-container-third-single-top{
    display: flex;
    flex-direction: column;
    padding: 7%;
    background-color: rgb(207, 6, 157); /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, rgb(251 139 103) , rgb(247 12 169 / 85%));
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    /* height:53px */
}
.register-customer-card-std{
    color: #fff;
    font-size: 0.85rem;
    margin: 1%;
}
.register-customer-card-std-price{
    color: #fff;
    font-size: 0.95rem;
    margin: 1%;
    font-weight: 600;
}
.register-customer-container-third-single-bottom{
    display: flex;
    flex-direction: column;
    padding: 4%;
    height: 66%;
    align-items: center;
}
.register-customer-card-icon-label{
    display: flex;
    align-items: center;
    margin: 3%;
    width: 228px;
}
.register-customer-card-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(159, 159, 255);
    width: 20px;
    height: 20px;
}
.register-customer-card-icon-label p{
    font-size: 0.8rem;
    font-weight: 600;
    /* margin-left:18px; */
    margin: 0 0 0 18px;
}
.register-customer-container-third-single-bottom button{
    background-color: #2626f5;
    color: #fff;
    width: 80%;
    height: 34px;
    border-radius: 4px;
    border: none;
    margin: 10% 0 0 0;
}
.platinum-card{
    height: 500px;
}
.platinum-card-top{
    background-color: red; /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, rgb(235, 195, 19), red);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.platinum-card-bottom{
    height: 77%;
}


.single-user-bottom-container{
    display: flex;
    flex-direction: column;
}
.single-user-first-container{
    display: flex;
    flex-wrap: wrap;
}
.single-user-top-single-card{
    padding: 1%;
    width: 30%;
    background-color: #fff;
    margin:8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #ffff;
    cursor: pointer;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
    border-radius: 8px;
}
.single-user-top-single-card-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3e3e6;
}
.single-user-top-single-card-image img{
    width: 50%;
}
.single-user-top-single-card p{
   font-size: 0.75rem;
   font-weight: 500;
   margin: 0 0 0 8px;
}

.single-user-second-container{
    display: flex;
    flex-direction: column;
    /* margin: 1%; */
}
.package-label{
    text-align: left;
    font-size: 0.9rem;
    font-weight: 600;
    /* background-color: #d3d3d3; */
    padding: 8px 14px;
    display: flex;
    margin: 1% 0;
    justify-content: space-between;
}
.package-label p{
    margin: 0;
}

.go-to-website{
    background: #e51448;
    color: #fff;
    width: 40px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .go-to-website p{
    font-size: 0.55rem;
    font-weight: 600;
    margin: 0;
  }
.new-global-table-container {
    padding: 1%;
    /* background-color: #fff; */
    max-height: 75vh;
    overflow-y: auto;
    width: 100%;
  }
  .new-global-table-container::-webkit-scrollbar {
    width: 4px;
    /* background-color: #d3e3ec; */
    background-color: #f1f1f1;
  }
  .new-global-table-container::-webkit-scrollbar-thumb {
    /* background-color: #a7c6d8; */
    background-color: #c1c1c1;
  }
  .new-global-table-container table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
  }
  .new-global-table-container table thead {
    position: sticky;
    top: -6px;
    background-color: #eef1f9;
    height: 50px;
    z-index: 2;
  }
  .new-global-table-container table thead tr th {
    height: 100%;
    text-align: center;
    font-size: 0.75rem;
  }
  .new-global-table-container table tbody {
    min-height: 36px;
  }
  
  .new-global-table-container table tbody tr td {
    border-bottom: 4px solid #edf2f9;
    height: 100%;
    text-align: center;
    min-height: 40px;
    height: 50px;
    font-size: 0.8rem;
    cursor: pointer;
  }
  @media (max-width: 450px) {
    .new-global-table-container {
      overflow-x: auto; /* Allow horizontal scrolling on smaller screens */
    }
    
    /* Reduce padding and font size on small screens */
    .new-global-table-container table thead tr th,
    .new-global-table-container table tbody tr td {
      font-size: 0.7rem;
      padding: 6px;
    }
    
    /* Reduce width of the table container */
    .new-global-table-container table {
      width: 100%;
    }
  }
  .status-active{
    border: 1px solid #04a604;
    padding: 6px;
    color: #04a604;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
  }
  .status-expired{
    border: 1px solid red;
    padding: 6px;
    color: red;
  }
  .disable-btn{
    cursor: not-allowed;
    color: #d1d1d1;
    border: 1px dashed #d1d1d1;
  }
  .my-plans-container-one-single-card .img1{
    width: 100%;
  }
  @media (max-width:850px){
  
    .my-plans-text-label{
        font-size: 0.8rem;
        font-weight: 500;
    }
    .my-plans-container{
        /* height: 92vh; */
    }
    .my-plans-container-one-single-card .img1{
        width: 85%;
      }
  }
  @media (max-width:450px){
  .my-plans-container-one-single-card .img1{
    width: 100%;
  }
   .card1{
   flex-direction: row;
  }
  .single-user-top-single-card{
    width: 100%;
    padding: 5%;
  }
    
  }