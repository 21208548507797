/** First CTA Section  */
.first-cta-section {
  display: flex;
 width: 90%;
 margin: 1% auto;
 justify-content: space-between;
}
/* Left container  */
.first-cta-section .first-cta-left-wrapper {
  flex: 55;
}
.first-cta-left-desc-container {
  width: 75%;
  margin-top: 10%;
  /* margin-left: 5%; */
}
.first-cta-left-desc-container h1 {
  word-spacing: 4px;
  margin: 2% 0;
  font-size: 2rem;
}
.description-ctn-mobile-view-h2{
  display: none;
}
.first-cta-left-desc-container p {
  margin-top: 4%;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 500;
  color: rgb(78, 78, 78);
}
.first-cta-left-wrapper div.first-cta-left-btn-container {
  width: 75%;
  margin: 4% 0;
}
.first-cta-left-btn-container button.demo-cta {
  background-color: transparent;
  border: none;
  color: #000;
  font-weight: 600;
  font-size: 1rem;
  margin: auto 1%;
  cursor: pointer;
  height: 40px;
  width: 30%;
}
.first-cta-left-btn-container button.demo-cta:hover {
  border-radius: 4px;
  border: 2px solid #000;
}

/* Right container  */
.first-cta-section .first-cta-right-wrapper {
  flex: 45;
  position: relative;
  display: flex;
}
.first-cta-section .first-cta-right-wrapper img{
  width: 100%;
}
.first-cta-right-wrapper .cta-right-img-container {
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  object-fit: fill;
  box-shadow: 2px 2px 10px rgb(228, 227, 227);
}
.first-cta-right-wrapper .login-img {
  width: 50%;
  right: -5%;
  top: 140px;
  transform: rotate(348deg);
}
.first-cta-right-wrapper .admin-img {
  width: 40%;
  left: 5%;
  top: 55px;
  object-fit: fill;
  transform: rotate(15deg);
}
.first-cta-right-wrapper .emp-dash-img {
  width: 70%;
  top: 76%;
  left: -92px;
  transform: rotate(348deg);
}
.first-cta-right-wrapper .cta-right-img-container img {
  width: 100%;
  object-fit: fill;
}

/** SECOND CTA SECTION  */
.second-cta-description-container {
  /* background-color: #fff;
  margin: 12% 3% 12% 0; */
  /* height: 30vh; */
  /* border-radius: 0 200px 200px 0; */
  display: flex;
  width: 90%;
  margin: 2% auto;
}
.second-cta-description-container .second-cta-left-container {
  flex: 45;
  position: relative;
  display: flex;
  align-items: center;
}
.second-cta-description-container .second-cta-left-container img {
  width: 72%;
  /* -webkit-transform: scaleX(-1); */
  /* transform: scaleX(-1); */
  position: absolute;
  /* bottom: 0; */
 
}
.home-purchase-btn{
  width: 30%;
  font-size: 1rem;
}
.second-cta-description-container .second-cta-right-container {
  flex: 55;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  padding: 9% 6% 9% 6%;
}
.second-cta-description-container .second-cta-right-container h2 {
  font-size: 2.2rem;
  margin-bottom: 2%;
}
.second-cta-description-container .second-cta-right-container p {
  font-size: 1.2rem;
  line-height: 1.7;
  font-weight: 500;
  color: #039b03;
}

.second-cta-description-container .second-cta-right-container button {
  align-self: flex-end;
  margin: 2% 0;
  width: 25%;
  height: 40px;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  background-color: #f2f6ff;
  box-shadow: 0 0 24px rgb(227, 233, 236);

  display: flex;
  justify-content: space-around;
  align-items: center;
}
.second-cta-description-container .second-cta-right-container button p {
  font-size: 1rem;
  font-weight: 600;
  color: #0026f3;
}
.second-cta-description-container .second-cta-right-container button img {
  width: 26%;
}
.second-cta-description-container .second-cta-right-container button:hover {
  background-color: #e0e8fa;
}

/** THIRD CTA SECTION  */
.purchase-products-section-parent {
  padding-left: 5%;
  /* margin-top: -5%; */
  position: relative;
}
.purchase-products-section-parent h2 {
  font-size: 1.5rem;
  margin-bottom: 2%;
}
.purchase-products-section-parent P.purchase-prod-description {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  width: 46%;
  color: rgb(78, 78, 78);
}
.purchase-products-card-container {
  margin: 1% 0; 
  display: flex;
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}
.purchase-products-card-container:hover >.product-card-container:not(:hover){
  opacity: 0.5;
}
.products-card-container:hover >.product-card-container:not(:hover){
  opacity: 0.5;
}
.purchase-products-card-container-right-scroll{
  position: absolute;
  top: 60%;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  background-color: #dadada;
  left: 3%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  color: grey;
  z-index: 9;
}
.purchase-products-card-container-left-scroll{
  position: absolute;
  top: 60%;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  background-color: #dadada;
  right: 0%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  color: grey;
  z-index: 9;
}
.purchase-products-card-container-left-scroll:hover,.purchase-products-card-container-right-scroll:hover{
  background-color: #767676;
  color: #fff;
}
.purchase-products-card-container::-webkit-scrollbar {
  display: none;
}
.purchase-products-card-container p {
  white-space: normal;
}
.purchase-products-card-container
  .purchase-prod-first-card {
  margin-left: 0;
}
.purchase-products-section-parent button.purchase-product-view-more-btn {
  background-color: #fff;
  width: 16%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 24px rgb(227, 233, 236);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin: 2% auto;
}
.purchase-products-section-parent button.purchase-product-view-more-btn p {
  font-size: 1rem;
  font-weight: 600;
  color: #0026f3;
}
.purchase-products-section-parent button.purchase-product-view-more-btn img {
  width: 18%;
}
.purchase-products-section-parent button.purchase-product-view-more-btn:hover {
  box-shadow: 0 0 24px rgb(204, 211, 214);
}
.section-three-hovered{
  opacity:0.2;
  transition: opacity 0.5s ease-in-out;
}
/** SECTION 4 */

.section-four-content-container {
  background-color: #fff;
  /* height: 20vh; */
  margin: 12% 0 2% 5%;
  border-radius: 200px 0 0 200px;
  position: relative;
  display: flex;
}

.section-four-content-container:hover >.section-four-content-child.image img{
 
  transform: scale(1.3);
  bottom: 90%;
  left: 85%;
  z-index:25;
  transition: transform 0.5s ease-in-out, bottom 0.5s ease-in-out, left 0.5s ease-in-out,z-index 0.5s ease-in-out;
}
.section-four-content-container:hover >.section-four-content-child.image .circle-container{
  opacity:1;
  transition: opacity 0.5s ease-in-out;
}
.section-four-content-container:hover >.section-four-content-child.description {
  padding-left:0;
  /* padding-top:10%; */
  margin-left:-8%;
  transform:scale(1.2);
  transition:transform 0.5s ease-in-out,padding-left 0.5s ease-in-out,padding-top 0.5s ease-in-out,margin-left 0.5s ease-in-out;
}
.section-four-content-container .section-four-content-child.image img {
  transform: scale(1);
  bottom: 35%;
  left: 20%;
  z-index: 1;
  transition: transform 0.5s ease-in-out, bottom 0.5s ease-in-out, left 0.5s ease-in-out, z-index 0.5s ease-in-out;
}
.section-four-content-container .section-four-content-child.image .circle-container{
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.section-four-content-container .section-four-content-child.description {
  padding: 6% 2% 6% 2%;
  margin: 2% 10% 2% 2%;
  transform: scale(1);
  transition:transform 0.5s ease-in-out,padding-left 0.5s ease-in-out,padding-top 0.5s ease-in-out,margin-left 0.5s ease-in-out;
}
.section-four-content-container .image {
  flex: 45;
  position: relative;
}

.section-four-content-container .image .circle-container {
  width: 60%;
  height: 100%;
  background-image: url("../../Assets/Images/Subtraction\ 8.png");
  background-repeat: no-repeat;
  background-position: 30% 55%;
  background-size: 82%;
}
.section-four-content-container .image div.orange {
  background-image: url("../../Assets/Images/Subtraction\ 9.png");
}
/* .section-four-content-container .image .circle-container img.violet-circle{
  position: absolute;
  left: 10%;
  top: 2%;
  width: 80%;
} */
.section-four-content-container .image img {
  width: 70%;
  position: absolute;
  bottom: 35%;
  left: 20%;
}
.section-four-content-container .description {
  flex: 55;
  display: flex;
  flex-direction: column;
  padding: 6% 2% 6% 2%;
  margin: 2% 10% 2% 2%;
}
.section-four-content-container .description h2 {
  font-size: 1.5rem;
  margin-bottom: 2%;
}
.section-four-content-container .description p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  color: rgb(78, 78, 78);
}
.section-four-content-container .description button {
  align-self: flex-end;
  margin: 4% 0;
  width: 25%;
  height: 40px;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  background-color: #f2f6ff;
  box-shadow: 0 0 24px rgb(227, 233, 236);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.section-four-content-container .description button p {
  font-size: 1rem;
  font-weight: 600;
  color: #0026f3;
}
.section-four-content-container .description button img {
  width: 26%;
}
.section-four-content-container .description button:hover {
  background-color: #e0e8fa;
}

/** SECTION 5 */
.section-five-content-container {
  display: flex;
  margin: 14% 5% 5%;
}
.section-five-content-container .section-five-left-container {
  flex: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 0;
}
.section-five-left-container h2 {
  font-size: 1.5rem;
  margin-bottom: 2%;
}
.section-five-left-container p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  color: rgb(78, 78, 78);
  width: 95%;
}

.section-five-left-container button {
  background-color: #fff;
  width: 26%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 24px rgb(227, 233, 236);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin: 8% 2% 4%;
  align-self: flex-end;
}
.section-five-left-container button p {
  font-size: 1rem;
  font-weight: 600;
  color: #0026f3;
}
.section-five-left-container button img {
  width: 27%;
}
.section-five-left-container button:hover {
  box-shadow: 0 0 24px rgb(204, 211, 214);
}

.section-five-content-container .section-five-right-container {
  flex: 50;
  position: relative;
}
.section-five-content-container
  .section-five-right-container
  img.purple-circle {
  position: absolute;
  left: 100px;
  top: 25%;
  width: 60%;
}
.section-five-content-container .section-five-right-container img.blue-circle {
  position: absolute;
  right: -135px;
  top: -15%;
  width: 45%;
}
.section-five-content-container .section-five-right-container img {
  width: 65%;
  position: absolute;
  right: 0;
  top: 10%;
}

/** SECTION 7 */
.home-section-seven-parent{
  padding-left: 5%;
}
.home-section-seven-parent-container {
  /* margin: 2% 3%;
  margin-right: 0; */
  position: relative;
}
.home-section-seven-parent-container h2 {
  font-size: 2rem;
  margin: 2% auto;
  width: fit-content;
}
.home-section-seven-parent-container .home-section-7-features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/** SECTION 8  */
.section-eight-parent-container {
  background-color: #fff;
  display: flex;
  padding: 4% 5% 4% 5%;
  /* height: 20vh; */
}
.section-eight-left-container {
  flex: 70;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-eight-left-container h2 {
  width: 90%;
  font-size: 2rem;
  margin-bottom: 3%;
}
.section-eight-left-container p {
  width: 90%;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  color: rgb(78, 78, 78);
}

.section-eight-right-container {
  flex: 30;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.section-eight-right-container img.sec8-orange-circle {
  position: absolute;
  left: 160px;
  top: 12px;
  width: 30%;
}
.section-eight-right-container img.sec8-pink-circle {
  position: absolute;
  right: -100px;
  bottom: -80px;
  width: 30%;
}
.section-eight-right-container iframe {
  width: 70%;
  height: 40vh;
  z-index: 5;
}

/** SECTION 9  */
.home-section-nine-parent {
  margin: 2% 0;
}
.home-section-nine-container {
  padding: 2% 5%;
  background-color: #fff;
  position: relative;
}
.home-section-nine-container h2 {
  margin: auto;
  margin-top: 2%;
  width: fit-content;
  font-size: 2rem;
}
.home-section-form-container {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: space-between;
  padding: 0 20%;
  margin-top: 3%;
}

.home-section-form-container div.single-input {
  width: 40%;
  margin: 2% 0;
  z-index: 10;
}
.home-section-form-container div.single-input p {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
}
.home-section-form-container div.single-input input {
  width: 100%;
  height: 40px;
  border: none;
  background-color: #ebf1ff;
  font-size: 1rem;
  border-radius: 4px;
  padding-left: 8px;
  
}
.home-section-form-container div.single-input input:focus {
  outline: none;
}
.home-section-form-container div.single-input textarea {
  width: 100%;
  height: 24vh;
  border: none;
  background-color: #ebf1ff;
  border-radius: 4px;
  padding: 4px;
  font-size: 1rem;
  resize: none;
}
.home-section-form-container div.single-input textarea:focus {
  outline: none;
}
.form-submit-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-submit-btn-container button {
  margin: 2% auto;
  height: 40px;
  padding: 8px 18px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  background-color: #004eff;
  border: none;
  border-radius: 4px;
  color: #fff;
}
.form-submit-btn-container button:hover {
  box-shadow: 0 2px 8px #c1c2c2;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgb(20 86 255)!important;
}
.home-section-nine-container img.query-pink {
  position: absolute;
  bottom: -40px;
  left: -120px;
  width: 35%;
}
.home-section-nine-container img.query-orange {
  position: absolute;
  top: 0;
  right: 80px;
  width: 20%;
}

.features-card .product-card-container{
  justify-content: start;
}
.mobile-section-four-img{
  display: none;  
}
/* responsive design */

@media(max-width:720px){
  .first-cta-section{
    flex-direction: column;
    margin: 0;
    margin-top: 5%;
  }
  
  .first-cta-left-wrapper{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 5%;
  }
  .first-cta-left-desc-container{
    width: 90%;
    margin: 0;
  }
  .first-cta-left-desc-container h1{
    font-size: 1.5rem;
  }
  .first-cta-left-desc-container p{
    font-size: 0.9rem;
  }
  .purchase-products-card-container-right-scroll{
    display: none;
  }
  .first-cta-left-wrapper div.first-cta-left-btn-container{
    width: 49%;
  }
 
  /* .first-cta-left-desc-container h1{
    font-size: 1.5rem !important;
  }
  .first-cta-left-desc-container p{
    font-size: 1rem !important;
  } */
  .first-cta-left-btn-container{
    margin: 8% 0% !important;
    width: 75%;
    display: flex;
  }
  .home-purchase-btn{
    margin-left: 0;
    font-size: 0.8rem !important;
    font-weight: 600;
    width: 45%;
  }
  .demo-cta{
    border-radius: 4px;
    font-size: 0.8rem !important;
    border: 1px solid #000 !important;
   
  }
  .first-cta-left-btn-container button.demo-cta{
    width: 49%;
  }
  .first-cta-right-wrapper{
    height: 50vh;
    flex: none !important;
  }
  .login-img{
    width: 45% !important;
    right: 2% !important;
    top: -25% !important;
    transform: rotate(360deg) !important;
  }
  .admin-img{
    top: 0 !important;
    width: 45% !important;
    transform: rotate(0deg) !important;
  }
  .emp-dash-img{
    width: 80% !important;
    top: 40% !important;
    left: 10% !important;
    transform: rotate(360deg) !important;
  }

  .second-cta-description-container{
   display: flex;
   flex-wrap: wrap;
  }
  .second-cta-description-container .second-cta-left-container{
    flex: none;
    width: 35%;
  }
  .description-ctn-mobile-view-h2 {
    display: flex;
    /* position: absolute; */
    font-size: 1.2rem;
    width: 80%;
    margin: 5%;
    margin-bottom: 5%;
  }
  .second-cta-description-container .second-cta-left-container img {
    width:100% !important;
    left: 10% !important;
  }
  .second-cta-description-container .second-cta-right-container{
    padding-top: 4%;
  }
  .second-cta-description-container .second-cta-right-container button {
    width: 70% !important;
    align-self: start !important;
    background-color: #e0e8fa !important;
    height: 30px !important;
    margin-top: 20px !important;
  }
  .second-cta-description-container .second-cta-right-container h2 {
    display: none;
  }
  .second-cta-description-container .second-cta-right-container p {
    font-size: 1rem !important;
  }
  .purchase-products-section-parent P.purchase-prod-description {
    width: 100%;
    font-size: 0.9rem;
  }
  .purchase-products-card-container{
    margin: 3% 1% !important;
  }
  .purchase-product-view-more-btn{
    width: 40% !important;
  }
  .section-four-content-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0;
    margin-left: 0;
  }
  .section-four-content-container .section-four-content-child.description {
    margin:2%;
    padding: 2%;
    text-align: center;
  }
  .section-four-content-container .description p{
    font-size: 0.9rem;
  }
  .mobile-section-four-img {
    width: 65%;
    display: flex;

    transition: transform 0.5s ease-in-out,margin 0.5s ease-in-out;
  }
  .mobile-section-four-img:hover{
    transform: scale(1.5);
    margin: 25px 0;
    transition: transform 0.5s ease-in-out,margin 0.5s ease-in-out;
  }
  .section-three-hovered {
    opacity: 1;
  }
  .section-five-left-container{
    align-items: center;
    padding: 0% !important;
    gap: 15px;
    text-align: center;
  }
  .section-four-content-container:hover >.section-four-content-child.description {
    padding-left:2%;
    padding-top:2%;
    margin-left:2%;
    transform:scale(1);
    transition:transform 0.5s ease-in-out,padding-left 0.5s ease-in-out,padding-top 0.5s ease-in-out,margin-left 0.5s ease-in-out;
  }
  .section-four-content-child h2{
    display: none;
  }
  .section-four-content-container .image{
    width: 30% !important;
    display: none;
  }
  .section-four-content-container .description{
    padding-top: 0;
    margin-right: 2%;
  }
  .section-four-h2{
    justify-content: center;
    margin-right: 2%;
    width: 85% !important;
  }
  .section-four-content-container .image .circle-container {
    width: 100%;
    background-position: 30% 20%;
    background-size: 100%;
  }
  .section-four-content-container .image img {
    width: 100% !important;
    bottom: 48% !important;
    left: 10% !important;
  }
  .section-four-content-container .description button {
    align-self: flex-start;
    width: 70% !important;
    margin-top: 20px !important;
    height: 30px !important;
  }
  .features-card .product-card-container {
    justify-content: start;
    align-items: center;
    text-align: center;
}
  .home-section-five-parent,.home-section-eight-parent{
    overflow-x: hidden;
  }
  .section-five-left-container button{
    width: 70% !important;
    align-self: start !important;
    height: 30px !important;
  }
  .section-five-content-container .section-five-right-container img {
    
    width: 70% !important;
  }
  .section-five-content-container {
    flex-direction: column;
    margin: 4%;
  }
  .section-five-content-container .section-five-right-container {
    display: none;
  }
  .section-five-content-container .section-five-right-container :nth-child(3){
    width: 90% !important;
    right: 2% !important;
    top: 27% !important;
  }
  .section-five-content-container .section-five-right-container img.blue-circle {
    top: 40% !important;
    right: 40% !important;
  }
  .section-five-content-container .section-five-right-container img.purple-circle {
    top: 15% !important;
    overflow-x: hidden;
    left: 45% !important;
  }
  .section-five-left-container p{
    font-size: 0.85rem;
  }
  .home-section-seven-parent{
    margin-top: 10%;
  }
  .home-section-seven-parent-container h2{
    margin-bottom: 5%;
  }
  .home-section-seven-parent-container .home-section-7-features-container {
    justify-content: center;
    height: 75vh;
    overflow-y: scroll;
  }
  .section-eight-parent-container {
    flex-direction: column;
  }
  .section-eight-left-container {
    align-items: center;
    text-align: center;
  }
  .section-eight-left-container h2{
    font-size: 1.2rem;
  }
  .section-eight-right-container {
    margin: 4% 0%;
    margin-left: 4%;
  }
  .section-eight-left-container p{
    font-size: 0.9rem;
  }
  .section-eight-right-container iframe {
    width: 100%!important;
    height: 30vh!important;
  }
  .section-eight-right-container img.sec8-orange-circle {
    top: 70% !important;
    left: -8% !important;
  }
  .section-eight-right-container img.sec8-pink-circle {
    bottom: 73% !important;
    right: -9% !important;
  }
  .home-section-nine-container {
    padding: 5% !important;
  }
  .home-section-nine-container h2 {
    width: 100% !important;
    font-size:1.5rem;
  }
  .home-section-form-container {
    flex-direction: column;
    padding: 0;
  }
  .home-section-form-container div.single-input {
    width: 95% !important;
  }
  .form-submit-btn-container {
    width: 100%;
    
  }
  .form-submit-btn-container button {
    font-weight: 500 !important;
    margin: 2% 0 !important;
  }
  .profile-user-container-btn{
    background-color: #f0f4ff!important;
  }

  .purchase-products-card-container:hover >.product-card-container:not(:hover){
    opacity: 1;
  }
  .products-card-container:hover >.product-card-container:not(:hover){
    opacity: 1;
  }
}

/* @media (min-width:1651px) {
.section-eight-parent-container{
    padding: 0 auto;
    width: 1650px;
  }
} */


/* new home page design */
/* container one*/

.home-conatiner-one{
  display: flex;
  width: 80%;
  margin: 2% auto;
}
.home-conatiner-one img{

}
.home-conatiner-one-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.home-conatiner-one-left h1{
   font-size: 1.7rem;
   margin: 2% 0 0 0;
   font-weight: 600;
}
.home-conatiner-one-left p{
  font-size: 1.2rem;
    color: #a19f9f;
    font-weight: 500;
    line-height: 1.7;
    width: 80%;
    margin: 3% 0;
}
.home-conatiner-one-left-btn-container{
  display: flex;
  width: 70%;
}
.home-conatiner-one-left-icon-container{
  display: flex;
  width: 50%;
  justify-content: center;
  margin: 2% 0;
}
.home-conatiner-one-left-icon-container a{
  margin: 0 3%;
}
.home-conatiner-one-right{
     width: 50%;
     display: flex;
    justify-content: end;
 
    
}
.home-conatiner-one-right img{
  width: 75%;


}

.home-conatiner-one-right-animation{
  position: absolute;
    top: 0%;
    right: 48%;
    width: 8%
  /* height: 100px; */
}
.home-conatiner-one-right-animation-graph{
  position: absolute;
  width: 35%;
  z-index: -1;
  left: -7%;
  top: -5%;

  
}
.home-conatiner-one-right-animation-setting-rotating{
  width: 8%;
  position: absolute;
}
/* container two*/
.home-conatiner-two{
  display: flex;
  width: 78%;
  margin: 1% auto;
}
.home-conatiner-two-left{
  width: 40%;
}
.home-conatiner-two-left img{
  width: 50%;
}
.home-conatiner-two-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}
.home-conatiner-two-right h2{
  font-size: 2.5rem;
  margin: 4% 0;
  color:#c8c8c8;
  position: relative;
  line-height: 1.5;
  margin: 0;
  font-weight: 700;
  
}
.home-conatiner-two-right span.Typewriter__cursor{
  visibility: hidden;
  position: absolute;
  top: 0;
}
.home-conatiner-two-right h2 span.Typewriter__wrapper{

  font-size: 2.5rem;
  position: absolute;
  color: #000;
  line-height: 1.5;
  top: 0;
  font-weight: 700;
 
  
}
.home-conatiner-two-right-in-erp{
  font-size: 2rem !important;
  color: #000 !important;
  margin: 0 !important;
}

.home-conatiner-two-right p{
    font-size: 1rem;
    font-weight: 600;
    color: #039b03;
    line-height: 1.7;
    margin: 0;
}
/* container three*/
.home-conatiner-three{
display: flex;
width: 85%;
margin: 1% auto;
flex-direction: column;
}
.home-conatiner-three-icon{
  display: flex;
  justify-content: space-around;
}
.home-conatiner-three-icon img{
  width: 4%;
}
.home-conatiner-three-border{
  display: flex;
  position: relative;
  margin: 1% 0;
  height: 20px;
}
.home-conatiner-three-border-line{
   border-bottom: 1px solid green;
   width: 100%;
}
.home-conatiner-three-border-line-round{
  width: 10px;
  height: 10px;
  border: 4px solid green;
  border-radius: 50%;
  position: absolute;
  padding: 1px ;
  background-color: #fff;


}
.home-conatiner-three-border-content{
  display: flex;
  justify-content: space-around;
  position: relative;
}
.home-conatiner-three-border-content-single{
  width:25%;
  display: flex;
  flex-direction: column;
  text-align: center;

}
.home-conatiner-three-border-content-single h5{
  font-size: 0.9rem;
  font-weight: 600;
}
.home-conatiner-three-border-content-single p{
    font-size: 0.75rem;
    font-weight: 500;
    margin: 2% 0;
    text-align: justify;
}
.home-conatiner-four-five-six{
  width: 100%;
  position: relative;
}
/* container four*/
.home-conatiner-four{
  display: flex;
 
}
.home-conatiner-four-content{
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 1% auto;
  position: relative;
  z-index: 2;
}
.home-conatiner-four-content-first-p{
  font-size: 1.5rem;
  margin: 0;

}
.home-conatiner-four-content-second-p{
  color: #019301;
}
.home-conatiner-four-content-third-p{
  font-size: 1rem;
  line-height: 2;
  width: 57%;
  margin: 1% 0;
}
.pink-background-image{
  position: absolute;
  width: 22%;
  right: -2%;
}
/* container five*/
.home-conatiner-five{
   margin: 3% 0;
   position: relative;
}
.home-conatiner-five-round{
  /* display: flex;
  flex-direction: column;
  width:550px;
  height:550px;
  border-radius: 50%;
  border: 2px dashed #afacac;
  justify-content: center;
  align-items: center;
  margin: 1% auto; */

  width: 500px; /* Adjust as needed */
  height: 500px; /* Adjust as needed */
  border: 2px dashed #afacac;
  border-radius: 50%;
  padding: 20px;
  margin-bottom: 20px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.round-first-p{
  font-size: 1.5rem;
    font-weight: 700;
    color: #03a303;
    margin: 3% 0;
}
.round-second-p{
    color: #03a303;
    margin: 3% 0;
    font-size: 1.2rem;
    font-weight: 500;
}
.round-third-p{
  font-size: 0.9rem;
  line-height: 1.5;
  width: 70%;
  text-align: center;
  font-weight: 600;
    margin: 3% 0;
}
.round-outline-btn{
  border: 1px solid rgb(3, 163, 3);
  /* width: 25%; */
  color: rgb(6, 167, 6);
  background-color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  height: 30px ;
  margin: 6% 0;
  padding: 1% 6%;

}
.home-conatiner-five-round-small-container{
  display: flex;
  flex-direction: column;
  width: 6%;
  position: absolute;

  align-items: center;
  justify-content: center;
   
}
.home-conatiner-five-round-small-container p{
  font-size: 0.8rem;
  font-weight: 600;
  margin: 8px 0;
}
.position-1 {
  top: -6%;
  left: 47%;
}
.position-2 {
  top: 10%;
  left: 47%;
}

.home-conatiner-five-round-small{
  width: 100px;
  height: 100px;
  background-color: #cddbfa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.home-conatiner-five-round-small img{
  width: 50%;
}
.textile-round{
  /* top: -54px;
  left: 217px  */

  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  
}
.logistic-round{
  left: -10%;
  top: 50%;
  transform: translateY(-50%);
}
.supermarket-round{
  bottom: -14%;
  left: 50%;
  transform: translateX(-50%);
}
.restaurant-round{
  right: -10%;
  top: 50%;
  transform: translateY(-50%);
}
.aviation-round{
    right: 36%;
    bottom: -15%; 
    width: 110px;
}
.healthcare-round{
    right: 28%;
    top: 62%;
    width: 110px;
}
.hospitality-round{
  right: 12%;
  top: 64%;
  width: 110px;
}
.education-round{
  right: -2%;
  top: 55%;
  width: 110px;
}
.product-path{
  position: absolute;
  right: -20%;
  bottom: -15%;
  width: 90%;
  z-index: -1;
}
.view-all-products-button{
    position: absolute;
    width: 16%;
    right: 4%;
  /* right: 10%;
  top: 48%; */
}
.view-all-products-button button{
  width: 100%;
  height: 40px;
  border: 1px solid green;
  background-color: transparent;
  color: #039e03;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}


/* container six*/
.home-conatiner-six{
  width: 80%;
  margin: 10% auto 6% auto;
  position: relative;
  height: 60vh;

}
.home-conatiner-six-first{
  display: flex;
  /* justify-content: space-between; */
  
}
/* .home-conatiner-six-first-left{
  width: 45%;
}
.home-conatiner-six-first-right{
  width: 45%;
}
.home-conatiner-six-first-left h2{
  font-size: 2rem;
  font-weight: 500;
}
.home-conatiner-six-first-left h1{
  font-size: 3rem;
  font-weight: 900;
  color: #919191;
} */
.home-conatiner-six-text{
   width: 50%;
   z-index: 2;
   position: relative;
 
}
.home-conatiner-six-text h2{
  font-size: 2rem;
  font-weight: 500;
 
}
.home-conatiner-six-text h1{
  font-size: 3rem;
  font-weight: 900;
  color: #919191;
  
}
.home-conatiner-six-card{
    background-color: #fff;
    box-shadow: #878585 10px 1px 24px -10px;
    padding: 1%;
    width: 45%;
    min-height: 120px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-conatiner-six-card-image{
  background-color: #ff0009;
  box-shadow: 0 0 10px 0px #ff0009;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 1%;
}
.home-conatiner-six-card-image img{
  width: 50%;
}
.home-conatiner-six-card-text{
  display: flex;
  flex-direction: column;
  width: 80%;
}
.home-conatiner-six-card-text h4{
  font-size: 0.9rem;
  font-weight: 600 !important;
  line-height: 1.5;
  margin: 1% 0;
}
.home-conatiner-six-card-text p{
  font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
}
/* container seven*/
.home-conatiner-seven{
    background-color: #0041d6;
    width: 80%;
    margin: 3% auto;
    border-radius: 8px;
    display: flex;
    position: relative;
    height: 190px;
    
}
.home-conatiner-seven-content{
  margin: 1% 1% 1% 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
}
.where-does-p{
  color: #fff;
  line-height: 1.5;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1% 0;
}
.where-does-content-p{
  color: #fff;
  line-height: 1.7;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 1% 0;
}
.home-conatiner-seven img{
  width: 18%;
  position: absolute;
  bottom: 0;
  right: 3%;
}
/* container eight*/
.home-conatiner-eight{
   width: 80%;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.home-conatiner-eight-video{
   width: 35%;
}
/* .home-conatiner-eight-video video{
 width: 100%;
 height: 400px;
} */
/* container nine*/
.home-conatiner-nine{
  display: flex;
  flex-direction: column;
}

.home-conatiner-nine-card-container{
  width: 100%;
}
.home-conatiner-nine-card-single{
  margin: 1% 0; 
  display: flex;
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  justify-content:  space-evenly;
} 
.home-conatiner-nine-card-single::-webkit-scrollbar {
  display: none;
}
.home-conatiner-nine-card-single:hover >.review-card-sigle:not(:hover){
  opacity: 0.5;
}
.review-card-sigle:hover >.review-card-sigle:not(:hover){
  opacity: 0.5;
}
.review-card-sigle{
  min-width: 350px;
  width: 25%;
  /* max-width: 400px; */
  height: 200px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 10px 0px rgb(174 169 169 / 50%);
  border-radius: 12px;
  padding: 1%;
  margin: 1%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.card-content{
  font-size: 0.85rem !important;
  margin: 1% 0;
  font-weight: 600;
  color: #a19f9f;
  white-space: normal;
}



 .review-card-sigle-image{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 1% 0;
}
.review-card-sigle-image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.review-card-sigle h4{
  color: blue;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 1% 0;
}
.review-card-sigle p{
  font-size: 0.9rem;
    margin: 3% 0;
    text-align: center;
    line-height: 1.5;
} 

.home-conatiner-nine-icon{
  width: 80%;
  margin: 1% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.home-conatiner-nine-icon-single{
  background-color: green !important;
  width: 30px;
  height: 30px;
  border-radius: 4px !important;
  margin: 1% !important;
  color: #fff !important;
  font-size: 0.9rem !important;
  font-weight: 700 !important;
} 
.clients-log{
  width: 10%;
}



/* container ten*/
.home-conatiner-ten{
  width: 80%;
  margin: 1% auto;
}
.ten-contact-info{
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 14px solid green;
  border-radius: 12px;
  /* height: 65vh; */
  box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
}
.ten-contact-info p{
  font-size: 1.3rem;
  font-weight: 700;
  border-bottom: 3px solid #000;
}
.ten-contact-info-content{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 2%;
}
.ten-contact-info-content-left{
  width: 40%;
  position: relative;
}
.ten-contact-info-content-right{
  width: 40%;
}
.ten-contact-info-content-left p{
   border: none;
   font-size: 1.1rem;
   margin-top: 2%;
}
.ten-contact-info-content-righ-card{
  padding: 6%;
  box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
  border-top: 14px solid green;
  border-radius: 12px;
  width: 70%;
  margin: 0 0 0 13%;
}
.ten-contact-info-content-righ-card-input{
  display: flex;
  flex-direction: column;
}
.ten-contact-info-content-righ-card-input p{
  font-size: 0.8rem;
    border: none;
    margin: 3% 0 0 0;
}
.ten-contact-info-content-righ-card-input input{
   height: 30px;
   border-radius: 4px;
   border: 1px solid #ada5a5;
}
.ten-contact-info-content-righ-card-input textarea{
 
  border-radius: 4px;
  border: 1px solid #ada5a5;
}

/* container five new */
.home-conatiner-five-new{
  width: 100%;
    height: auto;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.path3-imgae{
  width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -121px;
    left: 19%;
}


/* ..... */
.container-five {
   display: flex;
  justify-content: center;
  align-items: center;
   /* height: 100vh; */
  width: 100%;

  position: relative;
}

.circle-container {
  position: relative;
  width: 100%;
}

.dashed-circle {
  border: 2px dashed rgb(179, 177, 177);
  border-radius: 50%;
  width: 490px;
  height: 490px;
  position: relative;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 2% auto;
  flex-direction: column;
  z-index: 2;
}


.horizontal-path {
  position: absolute;
    width: 170%;
    left: -26%;
    bottom: 6%;
}

.path-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200%;
  position: relative;
  top: -12px;
}

.small-circle {
 /* Change color as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  
}
.small-circle p{
  font-size: 0.8rem;
  font-weight: 600;
  
}
.icon-circle{
  background-color: rgb(203, 215, 253); 
  width:80px;
  height:80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-circle img{ 
  width:70%
}

.inside-circle {
  position: absolute;
}

.path-circle {
  position: relative;
}

.product-gray-image{
  position: absolute;
  top: 0%;
  width: 25%;
  /* max-width: 570px; */
  left: 0%;
  z-index: 1;
}
.purchase-product-heading{
  position: absolute;
  top: 29%;
  left: 0%;
  z-index: 2;
  width: 8%;
}
.purchase-product-heading h1{
  font-size: xxx-large;
    color: #dcdcdc;
}


/* .... */
.home-conatiner-three-mobile{
  border-left: 2px solid rgba(29, 156, 3, 0.795);
  width: 80%;
  margin: 3% auto;
  position: relative;
  padding: 2% 0;
  display: none;
}
.home-conatiner-three-mobile-single{
  display: flex;
  flex-direction: column;
  width: 93%;
  margin: 2% 0% 2% 6%;
}
.home-conatiner-three-mobile-single h5{
   font-size: 0.9rem;
   font-weight: 600;
}
.home-conatiner-three-mobile-single p{
  font-size: 0.8rem;
  font-weight: 500;
}


.container-mobile-view {
  overflow-x: hidden;
}

.container-mobile-view-inner {
  display: flex;
  transition: transform 0.3s ease;
}

.container-mobile-view-single-card {
  flex: 0 0 auto;
  width: 100%;
}

.container-mobile-view {
  display: none;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; /* Enable momentum scrolling on iOS */
  /* white-space: nowrap;  */
}

.container-mobile-view-inner {
  display: inline-flex; /* Ensure all cards are displayed in a single row */
}

.container-mobile-view-single-card {
  flex: 0 0 auto;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px 0px rgb(174 169 169 / 50%);
  border-radius: 12px;
  margin: 2% 1%;
  /* height: 220px; */
  width: 280px;
  display: flex;
  flex-direction: column;

}
.container-mobile-view-single-card button{
    margin-top: auto;
    border: none;
    height: 45px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.home-nine-container-new{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-nine-container-new img{
  width: 40%;
}
.grow-up-your-business{
  font-size: 1.2rem;
  color: #8f8a8a;
  margin: 1% 0 0 0;
}
.with-us{
  font-size: 1.3rem;
  margin: 0;
  font-weight: 600;
}
.doc-validation-alert{
  color:red;
  font-size: 0.7rem !important;
  text-align: end;
  margin: 0 !important;
  /* position: absolute;
  top: 100%;
  right: 0%; */
}



@media(max-width:720px){
  .ten-contact-info-content{
    flex-direction: column;
    justify-content: center;
    align-items: center
  }
  .ten-contact-info-content-right{
    width: 90%;
  }
  .ten-contact-info-content-left{
    width: 90%;
  }
  .ten-contact-info-content-left img{
     display: none;
  }
  .ten-contact-info-content-righ-card{
    width: 100%;
    margin: 0;
  }
  .home-conatiner-six{
    display: flex;
    flex-direction: column;
    height: 72vh;
  }
  .home-conatiner-six-text{
     width: 100%;
  }
  .home-conatiner-six-card{
    width: 100%;
    position: initial;
    margin: 2% 0;
  }
  .home-conatiner-six-card-image{
    width: 50px;
    height: 50px;
  }
  .home-conatiner-six-card-text p{
    font-size: 0.75rem;
    line-height: 1.3;
  }
  .home-conatiner-six-text h1{
    font-size: 1.5rem;
  }
  .home-conatiner-seven-content{
    width: 92%;
    bottom:45px
  }
  .where-does-p{
    font-size: 1.1rem;
  }
  .where-does-content-p{
    font-size: 0.8rem;
  }

  .home-conatiner-seven{
    height: auto;
  }
  .home-conatiner-seven img{
    display: none;
  }
  .home-conatiner-eight-video{
    width: 100%;
  }
  .home-conatiner-one{
    flex-direction: column;
    width: 85%;
  }
  .home-conatiner-one-left{
    width: 100%;
  }
  .home-conatiner-one-left h1{
    font-size: 1.4rem;
  }
  .home-conatiner-one-left p{
    width: 100%;
    font-size: 1rem;
  }
  .home-conatiner-one-left-btn-container{
    width: 100%;
    margin: 2% 0;
  }
  .home-conatiner-one-left-icon-container{
    width: 100%;
  }
  .home-conatiner-one-right{
    width: 100%;
  }
  .home-conatiner-one-right{
    
  }
  .home-conatiner-two-left{
    width: 0%;
  }
  .home-conatiner-two-left img{
    display: none;
  }
  .home-conatiner-two-right{
    width: 100%;
  }
  .home-conatiner-two-right h2{
    font-size: 1.3rem;
  }
  .home-conatiner-two-right h2 span.Typewriter__wrapper{
    font-size: 1.3rem;
  }
  .home-conatiner-two-right-in-erp{
    font-size: 1.4rem !important;
  }
  .clients-log{
    width: 16%;
  }
  .home-conatiner-three-border-content{
    flex-direction: column;
  }
  .home-conatiner-three-border-content-single{
    width: 100%;
  }
  .product-gray-image{
    display: none;
  }
  .purchase-product-heading{
    display: none;
  }
  .home-conatiner-three{
     display: none;
  }
  .home-conatiner-three-mobile{
    display: block;
  }
  .home-conatiner-four-content-first-p{
    font-size: 1rem;
    font-weight: 500;
  }
  .home-conatiner-four-content-second-p{
    font-size: 1.5rem;
  }
  .home-conatiner-four-content-third-p{
    width: 100%;
    font-size: 0.9rem;
    line-height: 1.6;
  }
  .container-five{
    display: none;
  }
  .container-mobile-view {
    display: block;
  }
  .pink-background-image{
    right: 0;
    /* display: none; */
  }
  .ten-contact-info p{
    margin: 0;
  }
  .home-conatiner-two-right p{
    font-size: 0.8rem;
  }
  .home-conatiner-two{
    width: 85%;
  }
  .home-conatiner-three-mobile{
    width: 85%;
  }
  .home-conatiner-four-content{
    width: 85%;
  }
  .home-conatiner-six{
    width: 85%;
  }
  .home-conatiner-seven{
    width: 85%;
  }
  .home-conatiner-eight{
    width: 85%;
  }
  .home-conatiner-ten{
    width: 85%;
  }
}

@media(max-width:950px){
.dashed-circle {
width: 350px;
    height: 350px;}
  .view-all-products-button{
    width: 20%;
    right: 0%;}

.purchase-product-heading h1{
font-size: 2rem;
    color: #dcdcdc;
    padding-left: 17px;}}