.top-bar-select {
    background-color: #eee;
    padding: 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    /* flex-wrap: wrap; */
}

.top-bar-select-sub {
    width: 100%; /* Make the width responsive */
    max-width: 80%; /* Maintain a max-width */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    /* flex-wrap: wrap; Allow items to wrap */
}

.top-bar-select-sub p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
    border: 1px solid #eee !important;
}

.react-flags-select__selected-flag .flag-select__selected-default {
    display: none;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
    min-width: 140px;
}

.top-bar-select-sub-autocomplete {
    flex: 1; /* Allow the autocomplete to grow */
    min-width: 100px; /* Set a minimum width */
    margin: 0 4px; /* Add some margin */
    cursor: pointer;
}

.top-bar-select-sub-autocomplete
    .MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    height: 0px;
    width: 5px;
    display: flex;
    min-width: 50px;
    margin: 0;
    cursor: pointer;
}

.top-bar-select-sub-autocomplete .MuiOutlinedInput-notchedOutline {
    border-color: #eee !important;
}

.top-bar-select-sub-autocomplete .MuiAutocomplete-clearIndicator {
    display: none;
}

@media (max-width: 640px) {
    /* .ReactFlagsSelect-module_flagsSelect__2pfa2 {
        min-width: 100px;
        padding-right: 10px;
    } */

    .top-bar-select-sub p {
        font-size: 0.6em;
    }

    /* .top-bar-select-sub {
        justify-content: center; 
        flex-direction:column ; 
        align-items: stretch; 
    } */
}

/* Responsive adjustments for smaller screens */




