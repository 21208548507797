footer{
   display: flex;
   /* flex-direction: column; */
   /* justify-content: center; */
   /* align-items: center; */
   background: linear-gradient(to top, #000400, #022802);
  
   /* margin-top: 2%; */
   /* position: relative;
   overflow: hidden; */
}




.footer-container{
    display: flex;
    width: 80%;
    margin:2% auto;
    flex-direction: column;
}
.footer-content-container{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #fff;
}
.footer-content-container-single{
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
}
.footer-content-container-single p{
    font-size: 0.8rem;
    font-weight: 600;
    color: #fff;
    width: 65%;
    line-height: 2;
}
.footer-appstore-image-conatiner{
    display: flex;
    justify-content:flex-end;
    width: 32%;
}
.footer-appstore-image-conatiner img{
    width: 154px;
    height: 50px;
    margin: 0 1%;
}
.footer-list-container{
    display: flex;
    width: 50%;
}
.footer-list-single-container{
    width: 40%;
}
.footer-list-single-container h5{
   color: #fff;
   margin-bottom:12px;
   font-size: 1rem;
   margin: 0;
}
.footer-list-single-container p{
    line-height: 3;
    font-size: 0.75rem;
    margin: 0;
}
.footer-list-button-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.footer-list-button-subscribe{
    display: flex;
    width: 50%;
    justify-content: space-between;
}
.footer-list-button-subscribe input{
    background-color: #fff;
    height: 32px;
    width: 60%;
    padding: 1% 2%;
}
.footer-list-button-subscribe button{
   color: #000;
   cursor: pointer;
   width: 32%;
}
.footer-list-icon{
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin: 4%;
}

.footer-container-bottom-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1% 0;
}
.footer-container-bottom-container p{
    font-size: 0.8rem;
    font-weight: 600;
    color: #fff;
}



@media(max-width:720px){
    .footer-container{
        width: 92%;
       
    }
    .footer-content-container-single{
        flex-direction: column;
    }
    .footer-list-container{
        width: 100%;
    }
    .footer-list-single-container p{
        line-height: 2.5;
    }
    .footer-list-button-icon{
        width: 100%;
    }
}