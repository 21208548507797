.profile-info-container {
  /* display: flex;
  flex-direction: column;
  background-color: #fff;
 
  width: 96%;
  margin: 2%;
  border-radius: 9px;
  height: auto; */
  width: 100%;
  background-color: #ffffff69;
  border-radius: 5px;
  height: 90vh;
  display: flex;
  justify-content: center;
  margin-top: 0;
  background-image: url('../../Assets/Images/New/Mask\ Group\ 4.png');
  position: relative;
  background-size: cover;
}
.profile-info-input{
  width:45% !important
}
.my-plans-container-one-single-card{
  width: 30%;
  position: relative;
}
.profile-info-heading {
  display: flex;
  flex-direction: row;
  padding: 0 7%;
}
.profile-info-heading-left {
  display: flex;
  width: 80%;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 4%;
}
.profile-info-heading-right {
  display: flex;
  width: 20%;
  font-size: 1rem;
  align-items: center;
  justify-content: flex-end;
}
.skip-btn {
  color: sandybrown;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  margin: auto 1%;
  cursor: pointer;
  height: 40px;
  width: 30%;
}
.profile-info-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 7%;
  justify-content: space-between;
}
.profile-info-form-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-info-form-left {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.profile-info-form-right {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.profile-info-form-submit {
  text-align: center;
}
.profile-info-form-submit button{
  width: 32%;
  margin-top: 7%;
  background-color: #004eff;
  height: 45px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 8px;
}
.profile-info-form-submit-btn:hover {
  cursor: pointer;
}
.profile-info-footer {
  color: black;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 3%;
  /* height: 10vh; */
}
.profile-info-footer-link {
  color: #04c404;
  text-decoration: none;
}
.profile-info-footer-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.profile-info-image{
  position: absolute;
    top: 18%;
    /* left: 4%; */
    display: flex;
    justify-content: center;
  width:35%;
  bottom:0;
  left:1%;
}
.profile-info-section{
    width: 25%;
    padding: 1%;
    position: absolute;
    top: 30%;
    left: 30%;
}
.profile-info-section h1{
   font-size:1.3rem;
}
.profile-info-section p{
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.5;
}
.profile-info-form{
  border-radius: 8px;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 42vh;
    padding: 1%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 13%;
    right: 7%;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
}
.profile-info-form h1{
  font-size: 1.3rem;
}
.profile-info-image-form{
  position: absolute;
  width: 32%;
  display: flex;
  justify-content: flex-end;
  right: 0px;
  top: 53%;
}
.profile-info-image-form img{
  width: 80%;
}

@media (max-width:720px){
  .profile-info-input{
    width:100% !important
  }
  .profile-info-container{
    flex-direction: column;
    height: 122vh;
  }
  .profile-info-image{

    top: 65%;
    display: none;
  }
  .profile-info-section{
        width: 87%;
        top: 5%;
        /* height: 47vh; */
        left: 6%;
  }
  .profile-info-form{
        width: 88%;
        height: 48vh;
        top: 26%;
        right: 5%;
  }
}
@media (max-width: 850px) {
  .my-plans-container-one-single-card {
      display: flex;
      flex-direction: column; 
      align-items: center;    
      width: 100%;           
      margin-bottom: 20px;
        text-align: center;
        padding-top: 9px;
        height: 163px;
  }

  .my-plans-container-one-single-card img {
      width: 70%; 
      margin-bottom: 10px; 
  }
}
@media (max-width: 450px) {
 
  .my-plans-container-one-single-card {
      width: 100% !important; 
      display: flex;
      flex-direction: column; 
      align-items: center;    
      margin-bottom: 20px;  
  }

  .my-plans-container-one-single-card img {
      width: 70%; 
      margin-bottom: 10px; 
  }
}
.demo-blue-header-table{
  display:flex;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .demo-blue-header-table{
    display:unset;
    flex-wrap: wrap;
    width: 100%;
  }
  
}