.login-container {
 
    height: 84vh;
    background-image: url('../../Assets/Images/New/Path\ 1902\ \(2\).png');
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Centers the background image */
    display: flex;
    justify-content: center;
    align-items: center;
   

}
.login-background-image-container img{
  width: 100%;
}
.login-sub-container{
  display: flex;
  width: 70%;
  margin: 0% auto;
  box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
  height: 75vh;
  background-color: #fff;
 
  
  

}
.login-left-container {
  flex: 40 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-right-container {
  flex: 40;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-right-container img {
  width: 80%;
  /* margin: 15% 0; */
  /* height:100% */
}

/* login card css */

.login-card-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* padding: 1%; */
  width: 80%;
  height: auto;
  min-height: 50vh;
  /* margin: 4% 4% 4% 18%; */
  margin: auto;
  /* position:relative; */
}
.login-card-top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin-top: 8%;
    width: 90%;
    padding: 0 5%;
}
.login-card-top-container p{
  font-size: 1rem;
  font-weight: 600;
  /* padding: 3% 0; */
  margin-top: 2%;
}
.login-card-top-container img {
  width: 35%;
  /* height:20%; */
}
.login-card-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-card-button-container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  /* justify-content: flex-end;
    align-items: flex-end; */
  /* position: relative;
     bottom:-144px; */

  margin: 6% 0 4% 0;
}
.login-card-button-container p{

  font-weight:500;
  font-size: 1rem;
  text-align: center;
  padding: 2%;
  padding-bottom: 0;
}
.login-card-button-container span{

  font-weight:700;
  
}
/* .login-card-footer-container{
    display: flex;
    justify-content: center;
    align-items: center;
} */
.new-signup-form-section{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 2%;
}
.custom-input-validation{
  position:absolute;
  top: 100%;
  left:15%;
  font-size: 0.8rem;
  font-weight: 700;
  color: red;

}
.login-footer-link{
  color: #88C424;
  cursor: pointer;
}
.login-footer-link:hover{
  color: #4b7704;
}

.signup-suceess-popup{
  padding: 11%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.signup-suceess-popup p{
  width: 85%;
  font-weight: 600;
}
.signup-suceess-popup img{
  width: 100px;
}
.terms-and-condition-container-main{
  width:30vw
}
.terms-and-condition-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.terms-and-condition-container input[type="checkbox"]{
  accent-color:#88C424;
  width:12px;
  height: 12px;
  cursor: pointer;
  
}
.terms-and-condition-container label{
  font-size: 0.8rem;
  font-weight: 600;
  margin-left: 4px;
  cursor: pointer;
}
.terms-and-condition-popup{
  padding: 4%;
}
.terms-and-condition-popup-close-container{
  display: flex;
  justify-content: flex-end;
}
.terms-and-condition-popup-close-container i{
  font-size: 1.5rem;
  cursor: pointer;
}

/* media query */
@media(max-width:720px){
  .login-right-container{
    display: none;
  }
  .login-left-container {
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  .login-card-container{
    margin: 0 !important;
    width: 90%;
    /* min-height: 75vh !important; */
  }
  .custom-login-input {
    width: 90% !important;
  }
  .custom-input-validation{
    left: 5% !important;
  }
  .login-sub-container{
    width: 85%;
  }
  .terms-and-condition-container-main{
    width: 93vw;
  }
}