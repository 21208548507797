div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
    font-weight: 600;
    width: 91px;
    height: 40px;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel{
    border: 1px solid #000;
    width: 91px;
    color: #000;
    font-weight: 600;
    height: 40px;
}
.warning-alert-text{
    color: red;
    font-size: 1.1rem;
    font-weight: 500;
}
div:where(.swal2-container) h2:where(.swal2-title){
    font-size: 1.5rem;
    font-family:inherit;
}
.alert-popup-title{
    font-size: 1.1rem;
    color: #fff;
    margin: 6% 0 0 0;
}
.alert-popup-text{
    color: #fff;
    font-size: 1.3rem;
}
.alert-popup-button-ok{
    border: none !important;
     width: 88% !important;
     background-color: #fff !important;
     color: #000 !important;
}