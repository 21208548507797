.product-details-parent{
    /* position: fixed;
    width: 100%;
    background-color: #20212475;
    top: 0%;
    z-index: 20;
    height: 90vh;
    padding: 5vh 0;
    border-radius: 2px; */
    display: flex;
}
.product-details-dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    max-width: 95%;
    margin: 2%;
}
.product-details-container{
    background-color: #f2f6ff;
    width: 96%;
    height: 100%;
    position: relative;
    padding: 2%;
    background-image: url('../../Assets/Images/Down.png'),url('../../Assets/Images/Subtraction\ 7.png');
    background-position: 20% 80%,5% 20%;
    background-size: auto,35%;
    background-repeat: no-repeat,no-repeat;
}
.product-detail-topbar{
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    justify-content: space-between;
}
.product-single-view-image{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product-detail-topbar img{
    height: 45px;
    width: 45px
   
}
.product-detail-topbar h2{
    margin-left: 25px;  
}

.product-detail-image-ctn{
    display: flex;
    width: 100%;
    padding: 2% 0;
    background-color: #fff;
    border-radius: 4px;
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    position: relative;
    margin-bottom: 2%;
}
.product-detail-image-ctn::-webkit-scrollbar{
    display: none;
}
.product-detail-image-ctn img{
    width: 250px;
    margin: 0 2%;
}
.scroll-left-button{
    width: 50px;
    height: 50px;
    border: 1px solid #afafaf;
    border-radius: 50%;
    position: sticky;
    cursor: pointer;
    right: 4%;
    top: 40%;
    font-size: 1.2rem;
    box-shadow: 1px 1px 3px 1px #434345;
    padding: 13px;
    color: gray;
    background-color: #ececec;
}
.scroll-left-button:hover{
    width: 53px;
    height: 53px;
}
.global-hr {
    border: none;
    border-bottom: 1.5px solid rgb(182, 182, 182);
    width: 100%;
}

.product-detail-description-ctn p{
    margin: 0.5% 0;
    font-weight: 500;
    font-size: 1.2rem;
}
.product-detail-description-body{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.product-detail-description-body-left{
    width: 55%;
    padding: 1% 0;
} 
p.description-para{
    width: 85%;
    margin: 2% 0;
    font-weight: 500;
    color: #7c7c7c;
    font-size: 1rem;
}
p.description-para-list{
    width: 85%;
    margin: 1% 0;
    font-weight: 500;
    color: #7c7c7c;
    font-size: 1rem;
}
.product-detail-description-body-right{
    width: 25%;
    padding: 1% 0;
}
.product-detail-description-body-right-white-section{
    display: flex;
    background-color: #fff;
    position: relative;
    border-radius: 70px 0 0 70px;
    padding: 5%;
    justify-content: space-evenly;
    
}

.description-white-ctn-section{
    width: 45%;
    border-right: 1px solid rgb(182, 182, 182);
}
.description-white-ctn-section p.description-para{
    font-size: 0.7rem;
}

.product-detail-rating-ctn{
    width: 95%;
    padding-right: 3%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}
.product-detail-img-section{
    position: relative;
}
.product-detail-img-section .purchase-products-card-container-left-scroll{
    top: 50%!important; /* Adjust the vertical position as needed */
    right: 1%!important;
    transform: translateY(-50%)!important;
}
.product-detail-img-section .purchase-products-card-container-right-scroll{
    top: 50%!important; /* Adjust the vertical position as needed */
    left: 1%!important;
    transform: translateY(-50%)!important;
}

.product-detail-rating-ctn .products-card-btn{
    color: #fff;
    background-color: rgb(0 38 243);
    width: 15%;
}
@media(max-width:720px){
    .product-details-parent{
        height: auto;
        padding:0
    } 
    .product-details-container{
        padding: 2%;
    }
    .product-detail-topbar{
        padding:  2% 0;
        position: sticky;
        top: 0;
        z-index: 20;
        background-color: #f2f6ff;
    }
    .product-details-dialog .css-hz1bth-MuiDialog-container{
        padding-top: 0!important;
        justify-content: start;
        align-items: center!important;
    }
    .product-details-dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
        margin: 0%;
        max-height: 100%;
        max-width:100%;
        overflow-y: scroll;
    }
    .product-detail-description-body{
        flex-direction: column;
    }
    .global-hr{
        width: 98%;
    }
    .product-detail-description-body-left,.product-detail-description-body-right{
        width: 98%;
    }
    .product-detail-description-body-left .description-para,.description-white-ctn-section p{
        width: auto;
    }
    .product-detail-description-body-right-white-section {
        gap: 12px;
        justify-content: flex-end;
        padding-right: 5px;
    }
    .product-detail-rating-ctn{
        width: 100%;
        padding: 0;
        position: sticky;
        bottom: 0;
        z-index: 20;
        background-color: #f2f6ff;
    }
    .product-detail-rating-ctn .custom-login-input{
        width: 40%!important;
    }
    .product-detail-rating-ctn .products-card-btn{
        width: 50%;
    }
    .purchase-products-card-container-left-scroll{
        display: none;
    }
    .product-detail-topbar img{
        width:40px
    }
    .product-detail-topbar h2{
        margin-left: 15px;
    }
}