.topbar-parent-main{
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
  /* background: linear-gradient(to bottom, #f2f6ff, #ebf1ff);  */
  background-color: #fff;

}
.topbar-parent {
  max-width:80%;
  /* min-height: 32px;
  height: 5vh; *
  /* padding: 1% 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
   /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);  */
  /* position: sticky; */
   /* background: linear-gradient(to bottom, #f2f6ff, #ebf1ff);  */
  /* top: 0;
  left: 0;
  z-index: 20; */
  margin: 0 auto;
  height: 10vh;
}
.top-bar-parent-right{  
    display: flex;
    align-items: center;
    width: 60%;
    justify-content:space-between;
}
.topbar-parent img.logo {
  /* width: 10%; */
  width: 120px;
  margin-right: auto;
}
.top-bar-responsive-switch{
  display: none;
}
.cta-btn-container{
  width: 40%;
}
.topbar-parent .nav-menu-container ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
}
.topbar-parent .nav-menu-container {
  width: 50%;
  /* margin-ri  ght: 2%; */
}
.topbar-parent .nav-menu-container ul li {
  width: fit-content;
  font-size: 0.8rem;
  font-weight: 700;
}
.topbar-parent .nav-menu-container ul li a {
  text-decoration: none;
  color: #000;
  padding-bottom: 4px;
}
.topbar-parent .nav-menu-container ul li a:hover {
  border-bottom: 2px solid #0026f2;
  animation: border 0.2s ease-in;
}
.topbar-parent .cta-btn-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 50%;
}
.cta-btn-login-container{
  position: relative;
}
.topbar-parent .nav-menu-container ul li a.active {
  border-bottom: 2px solid #0026f2;
}
.userimage {
  width: 95%;
  height:95%;
  /* border: 2px solid rgb(255, 255, 255); */
  /* border-radius: 50% */
}
.profile-user-container{
  display: flex;
  flex-direction: column;
  width: 26vw;
  height: auto;
  background-color: #FFF;
  position: absolute;
  right: -5px;
  top: 147%;
  border-radius: 8px;
  box-shadow: 2px 2px 20px 1px #e5edf5;
  z-index: 1;
}
.profile-user-container ::after{
  content: "";
    position: absolute;
    top: -4%;
    right: 3%;
    z-index: 1;
    border-top: none;
    border-right: 16px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid white;
}
.profile-user-btn {
  border-radius: 50%;
  background-color: #026c02;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-user-container-btn{
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 4vh;
  margin: 5% 0% 2% 10%;
  margin-left: 5px;
  border: none;
  border-radius: 2px;
  background-color: #FFF;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.profile-user-container-btn:hover{
  background-color: #ebf1fe;
}
.profile-menu-icon{
  width: 20%;
  height: 2vh;
}
.profile-menu-right{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    
}
.top-bar-cart-container{
  display: flex;
  position: relative;
}
.top-bar-cart-round{
  background-color: red;
    padding: 1%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    right: 69%;
    top: -34%;
    bottom: 27%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.top-bar-cart-round p{
  margin: 0;
  font-size: 0.8rem;
}


/* media queries */

@media (max-width:720px){
  .topbar-parent{
    height: 7vh;
    padding-left: 15px;
    max-width: none;
  }
  .top-bar-responsive-switch{
    display: flex;
    position: relative;
    width: 70%;
    justify-content: flex-end;
  }
  .top-bar-parent-right{
    display: none;
  }
  .top-bar-parent-responsive-switch-menu{
    position: absolute;
    width: 90%;
    background: linear-gradient(to bottom, #f2f6ff, #ebf1ff);
    /* top: -90%; */
    border-radius: 20px 1px 1px 20px;
    right: -3%;
    height: 100vh;
    z-index: 2;
    padding-top: 6vh;
  }
}

.top-bar-menu-section{
  padding: 7% 10%;
  font-weight: 700;
  color: #000;
}
.top-bar-menu-section:hover{
  background-color: #ebf1ff;
}
.top-bar-menu-btn{
  display: flex;
  padding: 7% 0;
  justify-content: center;
  padding-bottom: 0;
}
.top-bar-menu-btn button{
  color: #000;
}