.custom-login-input {
  width: 90%;
  position: relative;
}
.custom-login-input p {
  font-size: 1rem;
  font-weight: 600;
  margin: 4% 0 2% 0;
}
.custom-login-input input {
  width: 93%;
  background-color: #eee;
  height: 30px;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  padding: 4px 11px;
}
.custom-login-input input:focus {
  outline: none;
}
.custom-login-input textarea{
  width: 93%;
  background-color: #ebf1ff;
  height: 48px;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  padding: 4px 11px;
  height: 80px;
}
.custom-login-input textarea:focus{
  outline: none;
}
/* .custom-login-input 
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 4px !important;
} */

.auto-complete div.MuiOutlinedInput-root{
  height: auto !important;
}
.auto-complete .MuiAutocomplete-input{
  padding: 4px 8px !important;
  
}
.auto-complete .MuiOutlinedInput-root{
  padding: 0px !important;
}
 .global-single-input
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  /* padding: 0 8px !important; */
}  
.custom-login-input div fieldset {
  border: none;
  border-radius: 0;
}
/* login button css */
.login-button-container {
  width: 90%;
}
.login-button-container button{
  width: 97%;
  background-color: #88C424;
  height: 45px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 8px;
}
.new-sign-in-button-green{
  width: 90%;
}
.new-sign-in-button-green button{
  width: 100%;
  background-color:#88C424;
  height: 45px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 8px;
}
.new-sign-in-button-green button:hover{
  background-color:#85d307;
}
@media(max-width:720px){
  .custom-login-input input {
    width: 99%;
  }
}