.free-trial-parent-container{
  width: 100%;
  background-color: #ffffff69;
  border-radius: 5px;
  height: 90vh;
  display: flex;
  justify-content: center;
  margin-top: 0;
  background-image: url('../../Assets/Images/New/Mask\ Group\ 3\ \(6\).png');
  position: relative;
  background-size: cover;
}
.free-demo-man-image{

position: absolute;
top: 18%;
left: 4%;
width: 25%;
display: flex;
justify-content: center;

}
.free-demo-man-image img{
width: 100%;
}
.free-demo-image {
position: absolute;
top: 49%;
right: 14%;
width: 32%;
}
.free-demo-image img{
width: 70%;
}

.free-trial-section{
  width: 25%;
  padding: 1%;
  position: absolute;
  top: 30%;
  left: 30%;
}
.free-trial-section h1.demo-header{
  margin:0;
  font-size: 1.3rem;
}
.free-trial-section .free-trial-left-para{
  margin: 6% 0;
  font-weight: 500;
  font-size: 1rem;
  width: 100%;
  color: #000000d9;
  line-height: 1.6;
}
.free-trial-blue-ctn{
/* background-color: transparent; */
border-radius: 8px;
align-items: center;
display: flex;
flex-direction: column;
width: 30%;
/* height: 42vh; */
padding: 1%;
background-color: rgba(255, 255, 255, 0.5);
position: absolute;
top: 13%;
right: 5%;
box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
}
.free-trial-blue-ctn h1.demo-blue-header{
  color: #000;
  margin: 4% 0;
  font-size: 1.1rem;
}
.free-demo-input {
  width: 100% ;
  position: relative;
  margin: 1%;
}
.free-demo-input p {
  font-size: 0.8rem;
  color: #000;
  font-weight: 600;
  margin: 3% 0 2% 0;
}
.free-demo-input input {
  width: 96%;
  background-color: #fff;
  height: 30px;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  padding: 4px;
  box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
}
.free-demo-input input:focus {
  outline: none;
}
.btn-get-demo{
  width: 98%;
  background-color: #000;
  border: none;
  font-size: 1rem !important;
  color: #fff;
  font-weight: 600 !important;
 
}
.free-demo-mobile-input{
  display: flex;
}
.free-demo-mobile-input input{
  width: 100%;
  border-radius: 0px 4px 4px 0px;
}
.free-demo-mobile-input .css-asbaxn-MuiAutocomplete-root{
  width: 25%;
}
.free-demo-mobile-input .MuiAutocomplete-input{
  border-radius: 4px 0px 0px 4px !important;
}
.free-demo-mobile-input .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none;
}

/* mediaquery */

@media (max-width:720px){
  .free-trial-parent-container{
    /* flex-direction: column;
    height: auto !important; */
  }
  /* .free-trial-section,.free-trial-left-para{
    width: auto!important;
  } */
 
  .free-demo-man-image{
    display: none;
  }
  .free-trial-section{
    width: 80%;
    left: 10%;
    top: 3%;
  }
  .free-trial-section .free-trial-left-para{
      font-size: 0.8rem;
      line-height: 1.8;
  }
  .free-trial-blue-ctn{
      width: 80%;
      top: 30%;
      left: 10%;
      /* height: 50vh; */
  }
  .free-demo-image{
    top: 81%;
    right: 1%;
    width: 50%;
  }


  
}
