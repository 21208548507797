.product-card-container{
    background-color: #fff;
    /* min-width: 29%; */
    min-width: 350px;
    width: 25%;
    /* min-width:400px ; */
    /* width: 400px; */
    margin:2%;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 4;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
    padding: 2%;
}
.product-card-top-container{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* margin: 8% 10% 4%; */
}
.product-card-image-container{
    width:60px;
    height:60px;
    border-radius:50%;
    background-color: rgb(123, 197, 247);
    display: flex;
    justify-content: center;
    align-items: center;
    /* opacity: 0.09; */
 
}
.product-card-image{
    height:40px;
    width:40px;
    
    
  
}
.product-card-top-container p{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 8%;
}

.product-card-middle-container{
    display: flex;
    flex-direction: column;
    /* margin: 2% 10% 4%; */
}
.product-card-middle-heading{
    font-size:1.2rem;
    font-weight:700;
    margin-bottom: 8px;
}
.product-card-middle-description{
    font-size:0.9rem;
    font-weight:500;
    color: rgb(78, 78, 78);

}
.product-card-bottom-container{
    display: flex;
    margin:6% 0;
    justify-content: center;
    align-items: center;
}
.product-card-bottom-container .product-card-view-span{
    font-size: 0.8rem;
    cursor: pointer;
    font-weight: 600;
    margin-right:5%;
}
.product-card-bottom-container .product-card-view-span:hover{
    font-size: 1rem;
    color: #333333;
}