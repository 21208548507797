.change-pass-ctn{
    display: flex;
    /* margin: 2%; */
    width: 100%;
    height: 90vh;
    /* padding-top: 7%; */
    background-color: #ffffffb0;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    background-image: url('../../Assets//Images//New/bgChangePassword.png');
}
.change-pass-ctn-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.change-pass-ctn-right{
    width: 40%;
    display: flex;
    justify-content: center;
}
.change-pass-ctn-right img{
    width: 27vh;
}
.change-pass-head{
    display: flex;
    justify-content: flex-start;
     width: 54%;
     flex-direction: column;
}
.change-pass-head h2{
    /* font-size: 2rem; */
    margin: 1% 0 1% 0px;
    /* font-weight: bold; */
    font-weight: 600;
}
.change-pass-head p{
    font-size: 0.9rem;
    line-height: 1.5rem;
    font-weight: 500;
}
.change-pass-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}
.change-pass-body-section{
    width: 60%;
    position: relative;
}
.change-pass-body-section input{
  width: 98%;
  background-color: #fff;
  height: 40px;
  border-radius: 9px;
  /* border: 1px solid rgb(100, 100, 100); */
  font-size: 1rem;
  padding: 4px;
  border: none;
  box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
}
.change-pass-body-section p{
  font-size: 0.9rem;
  font-weight: 500;
  margin: 8% 0 2% 0;
}
.change-pass-btn{
    width: 90%;
    text-align: center;
    margin: 5% 0;;
}
.change-pass-btn button{
    width: 60%;
    background-color: #005bff;
    color: #fff;
    border: none;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
}
.change-pass-btn button:hover{
    background-color: #93b9ff;
}

/* media query */

@media (max-width:720px){

    .change-pass-ctn{
        flex-direction: column;
        height: 95vh;
        justify-content: normal;
    }
    .change-pass-ctn-left{
        width: 100%;
    }
    .change-pass-head{
        display: block;
        width: 90% !important;
    }
    .change-pass-body{
        text-align: start
    }
    .change-pass-body-section {
        width: 100%;
    }
    .change-pass-btn button{
        width: 100%;
        margin: 4% 0 !important;
    }
    .change-pass-ctn-right img{
        width: 20vh;
    }
    .change-pass-head h2{
        font-size: 1.2rem;
    }
}