.checkout-page-container{
    width: 40vw;
    
}
.checkout-page-sub-container{
    padding: 4%;

}
.checkout-page-input-container{
    display: flex;
    flex-wrap: wrap;
}
.checkout-page-input-single{
    display: flex;
    flex-direction: column;
    width: 48%;
    margin: 1%;
}
.checkout-page-input-single p{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 4px 0;
}
.checkout-page-input-single input{
    height: 30px;
    border-radius: 4px;
    border: 1px solid #d1cece;
    width: 96%;
    padding: 4px;
}
.checkout-page-input-single input:focus{
    outline: none;
}
.autocomplete-checkout input{
    border:none !important;
    height: 7px;
}
.autocomplete-checkout
  .MuiOutlinedInput-root.Mui-focused
 {
  border-color: #bebebe !important;
}
.checkout-pay-button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkout-pay-button button{
    margin: 7% 1% 2% 1%;
    width: 30%;
    height: 35px;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}


.container-checkout input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  .container-checkout input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .container-checkout input[type=submit]:hover {
    background-color: #45a049;
  }
  
  .container-checkout {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  .buy {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .buy:hover {
    background-color: #45a049;
  }
  
  .containerIn {
    background-color: #f2f2f2;
  }