.login-footer-container{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
    width: 70%;
}
.login-footer-container p{
  font-weight:500;
  font-size: 1rem;
}
.login-footer-container span{
    font-weight: 700;
}
.google-sign-in-container{
    border: 1px solid rgb(214, 206, 206);
    width:97%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 4% 0;
}
.google-sign-in-container p{
    width:87%;
    text-align: center;
    font-size: 1.2rem;
    position: relative;
    left:12px
}
.google-sign-in-container span{
    color:red;
    position: relative;
    right:24px;
    top:4px;
    font-size: 1.3rem;
}
.login-footer-container h5{
    width:60%;
    text-align: center;
    color:#014eff
}
.login-footer-forgot-pass{
    padding:0% !important;
    font-size:0.9rem !important;
    color:#555555;
    cursor:pointer;
}
.login-footer-forgot-pass:hover{
    font-size:1rem!important;
    color:#2f2f2f;
}