.product-review-container{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}
.product-review-container-first{
    display: flex;
}
.product-review-container-first-content{
    width: 62%;
    margin: 4% 4% 1% 8%;
}
.flexible-plan{
    font-size: 1.7rem;
    font-weight: 600;
}
.flexible-plan-text{
    font-size: 0.9rem;
    font-weight: 500;
    color: #959494;
}
.product-review-container-erp-type{
    display: flex;

}
.single-product-details{
    display: flex;
    border: 1px dashed #000;
    padding: 1% 4%;
    margin: 4% 0;
}

.single-product-details p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.product-review-container-second{
    display: flex;
    width: 100%;
    justify-content: center;
}
.plan-card{
    margin: 1%;
}

.check-circle{
    margin-left: 8px;
    font-size: 1.4rem;
}
.star-fill{
    color:rgb(240, 216, 6);
    margin-left: 8px;
    font-size: 1.4rem;
}
.check2{
    margin-left: 8px;
    font-size: 1.4rem;
    color: #000;
}


@media (max-width:720px){
    .product-review-container-first{
        flex-direction: column;
    }
    .product-review-container-first-content{
        width: 100%;
        margin: 0;
    }
    .product-review-container-second{
        flex-direction: column;
        align-items: center;
        margin: 3% 0;
    }
    .product-review-container-first img{
        width: 50%;
        margin: auto;
    }
    .product-review-container-erp-type{
        justify-content: center;
    }
}