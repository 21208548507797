.products-main-container{
    background-image: url('../../Assets//Images//New/Mask\ Group\ 4\ \(1\).png');
    background-size: cover;
}
.products-content-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 90%;
    margin: 0px 45px 0px 0px;
    padding: 70px;
}
.product-heading{
    font-size:2rem;
    font-weight: 700;
    margin: 2% 0;
}
.products-sub-content-container{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-sub-content{
   
    font-size: 1.1rem;
    line-height: 1.5;
    font-weight: 400;
}
.products-card-container{
    /* padding: 2% 2% 1%; */
    place-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 4%;
    width: 90%;
    margin: 0 auto;
} 
.products-man-image{
    width: 394px;
    position: absolute;
    top: 4%;
    right: 10%;
}
.products-man-image img{
    width: 100%;
    transform: scaleX(-1);
}

/* media query 720px */

@media(max-width:720px){
    .products-content-container {
        /* padding: 5%; */
        align-items: start !important;
        padding-top: 0%;
        /* width: 70%; */
        margin: 2px;
    }
    
    .products-sub-content-container {
        width: 100% !important;
        align-items: start;
    }
    .product-sub-content {
        text-align: start;
        font-size: 0.9rem;
        line-height: 1.5;
    }
    .products-card-container{
        justify-content: center;
        /* height: 75vh;
        overflow-y: scroll; */
    }
    .product-heading{
        font-size: 1.3rem;
    }
    .products-man-image{
        width: 40%;
        top: 14%;
    }
    .products-man-image img{
        width: 100%;
    }
    .products-man-image{
        width: 61%;
        position: unset;
    }
}

