.btn{
    border-radius: 4px;
    height: 40px;
    font-size: 0.8rem;
    font-weight: 600;
    /* padding: 0 32px; */
    cursor: pointer;
    margin: auto 8px;
    border: none;

}

/* Contained Button  */
button.cta-contained-btn{
    border: none;
    background-color: #016701;
    color: #fff;
}
button.cta-contained-btn:hover{
    background-color: #03a003;
}

/* Outlined Button Css  */
button.cta-outlined-btn{
background-color: transparent;
border: 2px solid #03a003;
color: #039b03;
padding: 0;
}
button.cta-outlined-btn:hover{
    border: 2px solid #068806;
    color: #029e02;
}

.products-card-btn{
    display: flex;
    flex-direction: row;
    width:fit-content;
    height:35px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding:2% 6%;
    border: 1px solid #000;
    background-color: transparent;
}
.products-card-btn p{
    color: #000;
    font-weight: 600;
    margin: 0;
}
.products-card-btn-image{
    width:40px;
    margin-left:6px ;
}
.terms-and-condition-content{
    max-height: 50vh;
    min-height: 40vh;
    overflow-y: auto;
}
.terms-and-condition-content::-webkit-scrollbar{
    width: 8px;
    background-color: rgb(197, 217, 247);
}
.terms-and-condition-content::-webkit-scrollbar-thumb{
   
    background-color: rgb(127, 177, 252);
}
.btn-primary{
    background-color:rgb(13 97 251);
    color: #fff;
    width: 30%;
    font-size: 0.9rem;
    border: none;
    height: 34px;
    cursor: pointer;
    border-radius: 4px;
}
.btn-primary-outlined{
    border: 1px solid rgb(13 97 251);
    color: rgb(13 97 251);
    background-color: transparent;
    width: 11%;
    font-size: 0.9rem;
    height: 34px;
    cursor: pointer;
    border-radius: 4px;
}
.buy-now-button-cart{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(21, 74, 247);
    border: none;
    height: 40px;
    color: #fff;
    border-radius: 4px;
    width: 50%;
    box-shadow: 0 0 10px 0px rgb(21, 74, 247);
}
.free-trial-cart{
    height: 40px;
    width: 50%;
    border-radius: 4px;
    border: 1px dashed rgb(21, 74, 247);
    background-color: #fff;
    color: rgb(21, 74, 247);
    font-size: 0.8rem;
    font-weight: 500;
    padding: 1%;

    
}
.footer-text-cart{
    font-size: 0.65rem;
    width: 97%;
    /* display: flex; */
    text-align: center;
    font-weight: 600;
    color: #9c9c9c;
}
@media (max-width: 670px) {
    .btn-primary-outlined {
        width: 48%;  
        font-size: 0.7rem;
        height: 28px;
    }
}
@media (max-width: 470px) {
    .btn-primary-outlined {
        width: 19%;  
        font-size: 0.7rem;
        height: 28px;
    }
}
/* @media (max-width: 470px) {
    .single-user-top-single-card {
        width: 100%;  
        padding: 10%;
    }
}
@media (max-width: 670px) {
    .single-user-top-single-card {
        width: 50%;  
        padding: 5%;
    }
} */

